import { HTMLInputTypeAttribute } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

export type InputFieldProp = {
  type: HTMLInputTypeAttribute;
  label: string;
  placeholder: string;
  register?: UseFormRegisterReturn;
  error?: string;
};

const InputField = (prop: InputFieldProp) => {
  return (
    <>
      <label className=" font-semibold text-[#999999]" htmlFor={prop.label}>
        {prop.label}
      </label>
      <div className="mb-6 mt-2">
        <input
          {...prop.register}
          type={prop.type}
          className="form-control m-0 block w-full rounded-md border-0 bg-[#1b1a38] bg-clip-padding px-4 py-4 text-base font-semibold text-[#818191] transition ease-in-out placeholder:text-base placeholder:font-semibold placeholder:text-[#818191] autofill:bg-[#1b1a38] autofill:text-base focus:border-blue-600 focus:bg-[#1b1a38]  focus:text-[#818191] focus:outline-none active:bg-[#1b1a38]"
          placeholder={prop.placeholder}
        />
        <h5 className=" mt-3 text-sm text-red-400">{prop.error}</h5>
      </div>
    </>
  );
};

export default InputField;
