/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import shallow from 'zustand/shallow';
import InputField from '../../components/inputs/input-field';
import { Loader } from '../../components/loader/loader';
import { Snackbar } from '../../components/toast/Toast';
import { useBoundStore } from '../../lib/application/state';
import {
  AuthenticationStatus,
  ToastType,
} from '../../lib/common/constants/enum';
import { loginUserDto } from '../../lib/repositories/user_repo';
import { employeesRoute, resetPasswordRoute } from '../../lib/routes/routes';
import { notifySnackbar } from '../../lib/utils/utils';
import Logo from '../../public/images/logo.png';

type LoginFormValues = {
  email: string;
  password: string;
};

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Your email is required.')
    .email('Invalid email address provided.'),
  password: yup
    .string()
    .required('Your password is required.')
    .min(8, 'Your password is too short, minimum of 8 characters required.'),
});

const Login = () => {
  //access state events
  const { loginEvent, resetFailureEvent } = useBoundStore();

  // access state values
  // Array pick, re-renders the component when either state.authentication or state.isSubmitting change
  const [authentication, isSubmitting, failure] = useBoundStore(
    (state) => [
      state.authentication,
      state.isSubmittingLogin,
      state.failureLogin,
    ],
    shallow
  );

  // next router
  const router = useRouter();

  // register react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    resolver: yupResolver(validationSchema),
  });

  // on login button pressed
  const onSubmit = (data: loginUserDto) => {
    if (!isSubmitting) {
      loginEvent({
        email: data.email,
        password: data.password,
      });
    }
  };

  // react to authentication state and failure states
  useEffect(() => {
    if (authentication === AuthenticationStatus.authenticated) {
      router.push(employeesRoute);
      return;
    }
    if (failure.length !== 0) {
      notifySnackbar(failure, ToastType.warning);
      resetFailureEvent();
    }
  }, [authentication, failure]);

  return (
    <div className="flex h-screen">
      <Snackbar />
      <Head>
        <title>Login</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>

      {/* first half of the page */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex w-1/2 items-center justify-center bg-[#000517]"
      >
        <div className="mx-10 flex w-[50%] flex-col text-white">
          <div className="mb-10 flex w-full justify-center">
            <div className="relative h-24 w-24">
              <Image
                className=" rounded-xl"
                alt={'shiftswap logo'}
                unoptimized={true}
                layout="fill"
                src={Logo}
              />
            </div>
          </div>

          <h1 className="mb-6 flex w-full justify-center text-2xl font-semibold">
            Sign in to your dashboard
          </h1>

          {/* Email input  */}
          <div className="mb-4">
            <InputField
              label="Email"
              placeholder="Type your email"
              type="text"
              register={register('email', {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
              error={errors.email?.message ?? ''}
            />
          </div>

          {/* Password input  */}
          <InputField
            register={register('password', { required: true, minLength: 8 })}
            error={errors.password?.message ?? ''}
            label="Password"
            placeholder="Type your password"
            type="password"
          />

          {/* Remember me forgot password row*/}
          <div className="mt-2 flex justify-end">
            <Link href={resetPasswordRoute}>
              <h5 className="cursor-pointer text-sm font-medium text-[#6181f3]">
                Forgot password
              </h5>
            </Link>
          </div>

          {/* Login Button */}
          <button
            type="submit"
            className="mt-8 flex items-center justify-center rounded-md bg-[#6181f3] py-4 font-medium shadow-xl shadow-[#809cff]/20 hover:bg-[#3d5ed3]"
          >
            {isSubmitting ? <Loader /> : 'Login'}
          </button>
        </div>
      </form>

      {/* second half of the page */}
      <div className="flex w-1/2 items-center justify-center bg-[#131e42]">
        <div className="flex w-[80%] flex-col items-start text-white">
          <h1 className=" text-7xl font-black">Employee Shift Scheduling</h1>

          <h1 className="mt-8 text-xl font-normal">
            {
              'Shiftswap helps managers to create, manage, and keep track of employees\u0060 schedules efficiently.'
            }
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Login;
